import Image from 'next/legacy/image'

export default function JobsBanner() {
  return (
    <section className="p-0 m-0 bg-primary">
      <div className="container position-relative">
        {/* <!-- start desktop --> */}
        <div className="d-none d-lg-flex py-5 justify-content-center align-items-center">
          <h4 className="m-0 me-3 text-white">Find your next eyecare job</h4>
          <a
            href="https://jobs.eyesoneyecare.com/jobs/"
            className="btn btn-white-fancy me-2"
          >
            Browse Eyecare Jobs <span className="text-primary">(1,000+)</span>
          </a>
          <a
            href="https://jobs.eyesoneyecare.com/post-eyecare-jobs/"
            className="btn btn-white-fancy"
          >
            Post a Job
          </a>
        </div>
        {/* <!-- end desktop --> */}
        {/* <!-- start mobile --> */}
        <div className="d-lg-none mx-auto p-5 text-center">
          <h4 className="text-white mb-3">Find your next eyecare job</h4>
          <div className="d-flex flex-column justify-content-center">
            <a
              href="https://jobs.eyesoneyecare.com/jobs/"
              className="btn btn-white-fancy mb-2"
            >
              Browse Eyecare Jobs <span className="text-primary">(1,000+)</span>
            </a>
            <a
              href="https://jobs.eyesoneyecare.com/post-eyecare-jobs/"
              className="btn btn-white-fancy"
            >
              Post a Job
            </a>
          </div>
          {/* <!-- end mobile --> */}
        </div>
        <div
          style={{
            position: 'absolute',
            bottom: '0',
            right: '0',
            height: '200px',
          }}
        >
          <Image
            alt=""
            src="/stock/man-with-computer.png"
            width={200}
            height={200}
            className="d-none d-lg-block p-0 m-0"
            layout="intrinsic"
          />
        </div>
      </div>
    </section>
  )
}
