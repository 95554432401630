import Link from 'next/link'

import {z} from 'zod'

import SanityImage from '../sanity/SanityImage'
import {GetUniqueAuthorsDataSchema} from './types'

const FeaturedWritersPropsSchema = z.object({
  authors: GetUniqueAuthorsDataSchema,
  headline: z.string().optional(),
})

export default function FeaturedWriters({
  authors,
  headline = 'Featured Writers',
}: z.infer<typeof FeaturedWritersPropsSchema>) {
  if (!authors) {
    return null
  }

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between align-items-center">
              <h4>{headline}</h4>
              <a
                href="/contact/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-sm btn-outline-primary"
              >
                Become a Writer
              </a>
            </div>
            <hr />
          </div>
          <div className="col-12">
            <div className="row">
              {authors.map((author, i) => (
                <div
                  key={i}
                  className="col-4 col-md-3 col-lg-2 d-flex flex-column align-items-center mb-3 position-relative"
                >
                  <div className="avatar-hover-zoom d-flex flex-column w-100">
                    <Link href={`/authors/${author.slug}/`}>
                      <SanityImage
                        src={author.profilePicture}
                        alt={author.name}
                        width={250}
                        height={250}
                        className="rounded-circle"
                      />
                    </Link>
                  </div>
                  <div className="text-center">
                    <Link href={`/authors/${author.slug}/`}>
                      <small>{author.name}</small>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
