import {PortableText} from '@portabletext/react'
import {z} from 'zod'

import {featuredTextSerializers} from '../../core/serializers'
import {displayAuthorLinks, displayPublishedDate} from '../../core/utils'
import ContentBlock from '../cards/ContentBlock'
import {getResourceSlug} from '../resources/utils'
import {GetIndexPageQueryDataSchema} from './types'

const SponsoredPropsSchema = z.object({
  headline: z.string().optional(),
  resources:
    GetIndexPageQueryDataSchema.shape.resources.shape.sponsoredResources,
})

export default function Sponsored({
  resources,
  headline,
}: z.infer<typeof SponsoredPropsSchema>) {
  if (!resources.length) {
    return null
  }

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-12 mb-2">
            <span className="h4">{headline}</span>
            <hr />
          </div>

          <div className="col-lg-6">
            <ContentBlock
              image={resources[0].featuredImage}
              imageWidth={900}
              heading={resources[0].category.toUpperCase()}
              body={resources[0].featuredText}
              footer={displayAuthorLinks(resources[0].authors)}
              subfooter={displayPublishedDate(resources[0].publishedAt)}
              title={
                resources[0].titleOverride ? (
                  <h3 className="mb-n2">
                    <PortableText
                      value={resources[0].titleOverride}
                      components={featuredTextSerializers}
                    />
                  </h3>
                ) : (
                  <h3 className="mb-1">{resources[0].title}</h3>
                )
              }
              link={getResourceSlug(resources[0].slug)}
              flexDirection="column"
            />
          </div>

          <div className="col-12 col-lg-6 mt-2 mt-lg-0">
            <div className="row">
              {resources.slice(1, 5).map((resource) => (
                <div className="col-6" key={resource._id}>
                  <ContentBlock
                    image={resource.featuredImage}
                    imageWidth={300}
                    heading={resource.category.toUpperCase()}
                    footer={displayAuthorLinks(resource.authors)}
                    subfooter={displayPublishedDate(resource.publishedAt)}
                    title={
                      resource.titleOverride ? (
                        <h6 className="mb-n2">
                          <PortableText
                            value={resource.titleOverride}
                            components={featuredTextSerializers}
                          />
                        </h6>
                      ) : (
                        <h6 className="mb-1">{resource.title}</h6>
                      )
                    }
                    link={getResourceSlug(resource.slug)}
                    flexDirection="column"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
