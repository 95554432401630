import Link from 'next/link'

import {PortableText} from '@portabletext/react'
import {z} from 'zod'

import {featuredTextSerializers} from '../../core/serializers'
import {
  BasicResourceProjectionSchema,
  SpotlightProjectionSchema,
} from '../resources/schemas'
import SanityImage from '../sanity/SanityImage'

const SpotlightPropsSchema = z.object({
  resource: z.union([BasicResourceProjectionSchema, SpotlightProjectionSchema]),
  showSpotlight: z.boolean(),
  spotlightSponsorText: z.string().nullish(),
})

export default function Spotlight({
  showSpotlight,
  spotlightSponsorText,
  resource,
}: z.infer<typeof SpotlightPropsSchema>) {
  if (!showSpotlight || !resource) {
    return null
  }

  return (
    <section className="bg-black">
      <div className="container py-5 py-lg-2 px-5">
        <div className="row d-flex align-items-center">
          <div className="col-12 col-lg-4 mb-4 mb-lg-0">
            <Link
              href={
                'url' in resource
                  ? (resource.url as string)
                  : `/resources/${resource.slug}/`
              }
            >
              <SanityImage
                src={resource.featuredImage}
                alt={resource.title}
                width={560}
                height={315}
                className="rounded"
              />
            </Link>
          </div>
          <div className="col-12 col-lg-8">
            {spotlightSponsorText && (
              <span className="blocked text-primary">
                {spotlightSponsorText}
              </span>
            )}
            {
              <h3 className="h1 text-white">
                {'titleOverride' in resource && resource.titleOverride ? (
                  <PortableText
                    value={resource.titleOverride}
                    components={featuredTextSerializers}
                  />
                ) : (
                  resource.title
                )}
              </h3>
            }
            <div className="text-white mb-3">
              <PortableText
                value={resource.featuredText}
                components={featuredTextSerializers}
              />
            </div>
            <Link
              href={
                'url' in resource
                  ? (resource.url as string)
                  : `/resources/${resource.slug}/`
              }
              className="btn btn-outline-secondary"
            >
              {'buttonText' in resource ? resource.buttonText : 'Learn More'}
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}
